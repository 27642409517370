import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import ThawoloBanner from "../assets/img/thawolo-banner.webp"
import OlivioBanner from "../assets/img/olivio-banner.webp"


import Tagesmenu from "../assets/img/tagesmenu-menu-image.webp"
import Speisekarte from "../assets/img/speisekarte-menu-image.webp"
import Desserts from "../assets/img/desserts-menu-image.webp"
import Aktuell from "../assets/img/aktuell-menu-image.webp"
import Bankett from "../assets/img/bankett-menu-image.webp"


const Dater = ({ props }) => {
    var today = new Date();
    const [Active, setActive] = useState(false)
    const [Value, setValue] = useState("")
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    const mounths = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    const handleTodayClick = () => {
        const today = new Date();
        setCurrentMonth(today.getMonth());
        setCurrentYear(today.getFullYear());
    };
    const next = () => {
        setCurrentYear(currentMonth === 11 ? currentYear + 1 : currentYear);
        setCurrentMonth((currentMonth + 1) % 12);
    };
    const previous = () => {
        setCurrentYear(currentMonth === 0 ? currentYear - 1 : currentYear);
        setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1);
    };
    const daysInMonth = (iMonth, iYear) => {
        return 32 - new Date(iYear, iMonth, 32).getDate();
    };
    const Calendar = (month, year) => {
        const firstDay = new Date(year, month, 1).getDay();
        const daysInCurrentMonth = daysInMonth(month, year);

        const OldDate = ({ firstday, month, year, index }) => {
            return (
                <div className="old-day">
                    {daysInMonth(month === 0 ? 11 : month - 1, month === 0 ? year - 1 : year) - firstday + index + 1}
                </div>
            )
        }
        const CurrentDate = ({ date, month, year }) => {


            var status = "";
            if (year === today.getFullYear()) {
                if (month < (today.getMonth() + 1)) {
                    status = "disable";
                }
                else if (month === (today.getMonth() + 1)) {
                    if (date < (today.getDate())) {
                        status = "disable";
                    }
                    else {
                        status = ""
                    }
                } else {
                    status = ""
                }
            }
            const Control = () => {
                if (currentYear >= today.getFullYear()) {
                    if ((currentMonth + 1) === (today.getMonth() + 1)) {
                        if (date >= today.getDate()) {
                            return true
                        }
                    } else if ((currentMonth + 1) > (today.getMonth() + 1)) {
                        return true
                    }
                    else { return false }
                }
                else { return false }
            }
            const Number = (value) => {
                if (parseInt(value) < 10) {
                    return "0" + value
                } else {
                    return value
                }
            }

            return (
                <div
                    className={`current-day ${status ? "current-old-day" : "current-day"} ${month === (new Date().getMonth() + 1) && date === new Date().getDate() && "active"}`}
                    onClick={() => {
                        Control() && props.response({ year: year, month: Number(month), day: Number(date) })
                        Control() && setValue(`${Number(date)}.${Number(month)}.${year}`)
                        Control() && setActive(false)
                    }}
                >
                    {date}
                </div>
            )
        }
        const NextDate = ({ date, year, month, day }) => {
            var status = false;
            if (currentYear >= today.getFullYear()) {
                if ((currentMonth + 1) === (today.getMonth() + 1)) {
                    if (date >= today.getDate()) {
                        status = true
                    }
                } else if ((currentMonth + 1) > (today.getMonth() + 1)) {
                    status = true
                }
                else { status = false }
            }
            else { status = false }
            const Number = (value) => {
                if (parseInt(value) < 10) {
                    return "0" + value
                } else {
                    return value
                }
            }
            return (
                <div
                    className="next-day"
                    onClick={() => {
                        status && props.response({ year: year, month: Number(month), day: Number(day) })
                        status && setValue(`${Number(day)}.${Number(month)}.${year}`)
                        status && setActive(false)
                    }}
                >
                    {day}
                </div>
            )
        }
        const calendarBody = Array.from({ length: 6 }, (_, rowIndex) => {
            const row = Array.from({ length: 7 }, (_, columnIndex) => {
                const dayIndex = rowIndex * 7 + columnIndex;
                const date = dayIndex - firstDay + 1;
                if (rowIndex === 0 && columnIndex < firstDay) {
                    return (<td className="p-0"><OldDate firstday={firstDay} month={month} year={year} index={columnIndex} /> </td>);
                } else if (date > daysInCurrentMonth) {
                    return (<td className="p-0"><NextDate date={date} year={year} month={currentMonth + 2} day={date - daysInCurrentMonth} /> </td>);
                } else {
                    return (<td className="p-0"><CurrentDate date={date} month={month + 1} year={year} /> </td>);
                }
            });
            return <tr key={rowIndex}>{row}</tr>;
        });
        return calendarBody;
    };
    const Modal = () => {
        return (
            <>
                <div className="d-flex">
                    <div className="calendar-previous" onClick={previous}><i className="fa-solid fa-chevron-left"></i></div>
                    <div className="calendar-today" onClick={handleTodayClick}>{currentYear}</div>
                    <div className="calendar-today" onClick={handleTodayClick}>Heute</div>
                    <div className="calendar-today" onClick={handleTodayClick}>{mounths[currentMonth]}</div>
                    <div className="calendar-next" onClick={next}><i className="fa-solid fa-chevron-right"></i></div>
                </div>
                <table className="calendar-table">
                    <thead>
                        <tr>{days.map((day, index) => (<th key={index} className="p-0"><div className="week-day">{day}</div></th>))}</tr>
                    </thead>
                    <tbody>
                        {Calendar(currentMonth, currentYear)}
                    </tbody>
                </table>

            </>
        );
    }

    return (
        <>
            <input className={`form-control h-50px shadow-sm cursor-pointer ${props?.classname}`} placeholder="Datum auswählen *" value={Value} onClick={() => { setActive(true) }} />
            <div className="calendar-modal-container">
                <div className={`calendar-modal shadow-sm ${Active ? "active" : ""}`}>
                    {Modal()}
                </div>
            </div>
            <div className={`calendar-modal-ovarlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }}></div>
        </>
    )

}
const Timer = ({ props }) => {
    const [Active, setActive] = useState(false)
    const [Value, setValue] = useState("")
    const hour = [
        { id: 1100, time: "11:00-11:30" },
        { id: 1130, time: "11:30-12:00" },
        { id: 1330, time: "12:00-12:30" },
        { id: 1230, time: "12:30-13:00" },
        { id: 1300, time: "13:00-13:30" },
        { id: 1330, time: "13:30-14:00" },


        { id: 1700, time: "17:00-17:30" },
        { id: 1730, time: "17:30-18:00" },
        { id: 1800, time: "18:00-18:30" },
        { id: 1830, time: "18:30-19:00" },
        { id: 1900, time: "19:00-19:30" },
        { id: 1930, time: "19:30-20:00" },
        { id: 2000, time: "20:00-20:30" },
        { id: 2030, time: "20:30-21:00" },
        { id: 2100, time: "21:00-21:30" },
        { id: 2130, time: "21:30-22:00" },
    ]
    const Control = (time) => {
        var d = new Date();
        var day = d.getDate();
        if (parseInt(props?.selected.split(".")[1]) > d.getMonth() + 1) {
            return false
        } else {
            if (parseInt(props?.selected.split(".")[0]) === parseInt(day)) {
                if (parseInt(d.getHours() + 1) > parseInt(time.split("-")[0].split(":")[0])) {
                    return true
                } else {
                    return false
                }
            }
            else if (props?.selected.split(".")[0] > day) {
                return false
            }
            else {
                return true
            }
        }


    }
    return (
        <>
            <input className={`form-control h-50px shadow-sm cursor-pointer ${props?.classname}`} placeholder="Zeit auswählen *" value={Value} onClick={() => { setActive(true) }} />
            <div className="timer-container">
                <div className={`timer-modal ${Active ? "active" : ""} shadow-sm`}>
                    {
                        props?.selected &&
                        (hour || []).map((d, x) => {
                            return (
                                <div className={`timer-modal-item ${Control(d.time) ? "disable" : ""}`} onClick={() => { props?.response(d.time); setValue(d.time); setActive(false); }}>
                                    {d.time}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={`timer-overlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }} ></div>
        </>
    )
}
const Header = ({ props }) => {
    const navigate = useNavigate();
    const [Sticky, setSticky] = useState(0)
    const [Active, setActive] = useState(false)
    const [Toggle, setToggle] = useState(false)
    const LoadingBar = () => {
        useEffect(() => {
            function handleScroll() {
                var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
                var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
                var scrolled = (winScroll / height) * 100;
                document.getElementById("progressBar").style.width = scrolled + "%";
            }
            window.addEventListener('scroll', handleScroll);
            return () => { window.removeEventListener('scroll', handleScroll); };
        }, []);

        return (
            <div className="progress-bar" id="progressBar" style={{ background: '#ce1014', height: '5px', width: '0%', position: 'fixed', zIndex: 1040 }}>
            </div>
        );
    }
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 40) {
                setSticky(1)
            } else {
                setSticky(0)
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.scroll({
                top: 0,
                left: 0,
            });
        };
    }, []);
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, [window.location.pathname])
    return (
        <>
            <div className={`top md-d-none lg-d-flex`}>
                <div className="container d-flex">
                    <div className="w-100 d-flex">
                        <div className="top-menu me-2 ms-1" onClick={() => { window.open(`https://www.instagram.com/capri.pizzeria/`) }}><i className="fa-brands fa-square-instagram"></i></div>
                        <div className="top-menu" onClick={() => { window.open(`https://www.facebook.com/CapriAdliswil`) }}><i className="fa-brands fa-square-facebook text-white"></i></div>
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                        <div className="top-menu" onClick={() => { navigate("/unsere-weine") }}>Unsere Weine</div>
                        <div className="top-menu ms-5" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`) }}>Hauslieferung</div>
                        <div className="top-menu ms-5" onClick={() => { navigate("/feiern") }}>Feiern</div>
                    </div>
                </div>
            </div>
            <div className={`header ${Sticky === 1 ? "sticky" : ""}`}>
                <div className="container position-relative">
                    <div className="d-flex md-d-none lg-d-flex">
                        <div className="w-100px cursor-pointer" onClick={() => { navigate("/") }}>
                            <div className="header-logo">
                                <img src={`https://ristorante-olivo.ch/api/upload/${props?.logowhite}`} className="logo-image" />
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <div className="header-menu" onClick={() => { navigate("/") }}>HOME</div>
                            <div className="header-menu">
                                MENÜ
                                <i className="fa-solid fa-chevron-down ms-1"></i>
                                <div className="header-menu-modal">
                                    <div className="w-100 me-2 ms-1">
                                        <div className="header-menu-modal-image" onClick={() => { navigate("/menu/tagesmenu") }} style={{ backgroundImage: `url(${Tagesmenu})` }}></div>
                                        <div className="header-menu-modal-title">TAGESMENÜ</div>
                                    </div>
                                    <div className="w-100 me-2 ms-1">
                                        <div className="header-menu-modal-image" onClick={() => { navigate("/menu/speisekarte") }} style={{ backgroundImage: `url(${Speisekarte})` }}></div>
                                        <div className="header-menu-modal-title">SPEISEKARTE</div>
                                    </div>
                                    <div className="w-100 me-2 ms-1">
                                        <div className="header-menu-modal-image" onClick={() => { navigate("/menu/desserts") }} style={{ backgroundImage: `url(${Desserts})` }}></div>
                                        <div className="header-menu-modal-title">DESSERTS</div>
                                    </div>
                                    <div className="w-100 me-2 ms-1">
                                        <div className="header-menu-modal-image" onClick={() => { navigate("/menu/aktuell") }} style={{ backgroundImage: `url(${Aktuell})` }}></div>
                                        <div className="header-menu-modal-title">AKTUELL</div>
                                    </div>
                                    <div className="w-100 me-1 ms-2">
                                        <div className="header-menu-modal-image" onClick={() => { navigate("/menu/bankett") }} style={{ backgroundImage: `url(${Bankett})` }}></div>
                                        <div className="header-menu-modal-title">BANKETT</div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-menu" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`) }}>HAUSLIEFERUNG</div>
                            <div className="header-menu" onClick={() => { navigate("/jobs") }}>JOBS</div>
                            <div className="header-menu" onClick={() => { navigate("/galerie") }}>GALERIE</div>
                            <div className="header-menu" onClick={() => { navigate("/kontakt") }}>KONTAKT</div>
                        </div>
                        <div className="w-200px">
                            <div className="header-button">
                                <div className="btn btn-red" onClick={() => { navigate("/tisch-reservation") }}>TISCH RESERVATION</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex md-d-flex lg-d-none">
                        <div className="w-200px">
                            <div className="header-logo" onClick={() => { navigate("/") }}>
                                <img src={`https://ristorante-olivo.ch/api/upload/${props?.logowhite}`} className="logo-image" />
                            </div>
                        </div>
                        <div className="header-hamburger">
                            <i className="fa-solid fa-bars" onClick={() => { setActive(true) }}></i>
                        </div>
                    </div>
                </div>
                <LoadingBar />
            </div>
            <div className={`drawer ${Active ? "active" : ""} shadow-sm`}>
                <div className="drawer-header">
                    <div className="w-100 p-2" onClick={() => { navigate(`/`) }}>
                        <img src={`https://ristorante-olivo.ch/api/upload/${props?.logocolor}`} className="logo-image" />
                    </div>
                    <div className="w-100 p-2 d-flex justify-content-end">
                        <i className="fa-solid fa-circle-xmark text-white" onClick={() => { setActive(false) }}></i>
                    </div>
                </div>
                <div className="drawer-body">
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/" }}>HOME</div>
                    <div className="drawer-body-item" onClick={() => { Toggle ? setToggle(false) : setToggle(true) }}>
                        <div className="w-100">MENÜ</div>
                        <div className="w-100 d-flex justify-content-end me-2">
                            <i className="fa-solid fa-chevron-down fs-1"></i>
                        </div>
                    </div>
                    <div className={`drawer-body-item-toggle ${Toggle ? "active" : ""}`}>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/tagesmenu" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            TAGESMENÜ
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/speisekarte" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            SPEISEKARTE
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/desserts" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            DESSERTS
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/aktuell" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            AKTUELL
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/bankett" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            BANKETT
                        </div>
                    </div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/unsere-weine" }}>UNSERE WEINE</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/tisch-reservation" }}>TISCH RESERVATION</div>
                    <div className="drawer-body-item" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`, "blank") }}>HAUSLIEFERUNG</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/feiern" }}>FEIERN</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/galerie" }}>GALERIE</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/kontakt" }}>KONTAKT</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/jobs" }}>JOBS</div>
                </div>
                <div className="drawer-footer">
                    <div className="drawer-footer-item" onClick={() => { window.open(`mailto:${props?.email}`) }}>{props?.email}</div>
                    <div className="drawer-footer-item" onClick={() => { window.open(`tel:${props?.phone}`) }}>{props?.phone}</div>
                    <div className="drawer-footer-social">
                        <i className="fa-brands fa-facebook fs-1hx text-white me-2 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/profile.php?id=100064681705058`, "blank") }}></i>
                        <i className="fa-brands fa-square-instagram fs-1hx text-white cursor-pointer" onClick={() => { window.open(`https://www.instagram.com/ristorante.olivo/`, "blank") }}></i>
                    </div>
                </div>
            </div>
            <div className={`drawer-overlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }}></div>
            <div className={`header-height`}></div>
        </>
    )
}
const Footer = ({ props }) => {

    return (
        <div className="footer">
            <div className="container lg-pt-c5 lg-pb-c5  md-pt-c5 md-pb-c5">
                <div className="row">
                    <div className="col-lg-4 col-12 md-pb-c2">
                        <div className="footer-title md-pb-c1">KONTAKT</div>
                        <div className="footer-text d-flex md-pb-c1">
                            <div className="w-30px me-2"><i className="fa-solid fa-location-dot"></i></div>
                            <div className="w-100">{props?.name} <br /> {props?.district} <br /> {props?.address}</div>
                        </div>
                        <div className="footer-text d-flex md-pb-c1 cursor-pointer" onClick={() => { window.open(`tel:${props?.email}`) }}>
                            <div className="w-30px me-2"><i className="fa-solid fa-envelope"></i></div>
                            <div className="w-100">{props?.email}</div>
                        </div>
                        <div className="footer-text d-flex md-pb-c1 cursor-pointer" onClick={() => { window.open(`tel:${props?.phone}`) }}>
                            <div className="w-30px me-2"><i className="fa-solid fa-square-phone-flip"></i></div>
                            <div className="w-100">{props?.phone}</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 md-pb-c2">
                        <div className="footer-title md-pb-c1">ÖFFNUNGSZEITEN</div>
                        <div className="footer-text md-pb-c1">
                            <div className="row">
                                <div className="col-lg-6 d-flex md-pb-c1">
                                    <div className="me-2"><i className="fa-solid fa-clock me-1"></i></div>
                                    <div className="w-100">Montag-Freitag:</div>
                                </div>
                                <div className="col-lg-6 md-pb-c1">
                                    11:00-14:00 Uhr
                                </div>
                                <div className="col-lg-6 md-pb-c1">
                                </div>
                                <div className="col-lg-6 md-pb-c1">
                                    17:00-22:30 Uhr
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 d-flex md-pb-c1">
                                    <div className="me-2"><i className="fa-solid fa-clock me-1"></i></div>
                                    <div className="w-100">Sa-So/Feiertage:</div>
                                </div>
                                <div className="col-lg-6 md-pb-c1">
                                    17:00-22:30 Uhr
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 md-pb-c2">
                        <div className="footer-title md-pb-c1">FILIALEN</div>
                        <div className="footer-banner md-pb-c1 cursor-pointer" onClick={() => { window.open(`https://www.thawolo.ch/`) }}>
                            <img src={ThawoloBanner} />
                        </div>
                        <div className="footer-banner md-pb-c1 cursor-pointer" onClick={() => { window.open(`https://ristorante-olivo.ch/`) }}>
                            <img src={OlivioBanner} />
                        </div>
                    </div>

                    <div class="col-lg-12 lg-pt-c3 md-pt-3 md-pb-c1">
                        <div class="text-white">© Copyright Designed <a href="https://kompassgroup.ch/">Kompass Group GmbH</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Upload = ({ props }) => {
    const clickRef = useRef()
    const [Data, setData] = useState("")
    const Select = (e) => {
        e.preventDefault();
        const [file] = e.target.files;
        setData(file.name)
        props.response(file)
        if (clickRef.current) {
            clickRef.current.value = "";
        }
    }

    return (
        <>

            <div className={`upload-container shadow-sm ${props.classname}`} onClick={() => { clickRef.current.click() }}>
                {
                    Data === "" ?
                        <div>
                            <div className="text-center"><i className="fa-solid fa-cloud-arrow-up"></i></div>
                            <div className="text-center">Datei auswählen...</div>
                        </div>
                        :
                        <div>
                            {Data}
                        </div>
                }

            </div>
            <input type="file" ref={clickRef} onChange={Select} hidden />

        </>
    )
}
const Section = ({ props, children }) => {

    return (
        <>
            <div className={props["classname"]["container"]} style={{ backgroundImage: `url(${props["url"]})` }}>
                <div className={props["classname"]["inner"]}>
                    <div className="section-title">{props["title"]}</div>
                    <div className="section-text">{props["text"]}</div>
                    <div className={props["classname"]["body"]}>{children}</div>
                </div>
            </div>
        </>
    )
}
const Clicktop = () => {
    const [Sticky, setSticky] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 300) {
                setSticky(1)
            } else {
                setSticky(0)
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    return (
        <div className={`scroll-top cursor-pointer ${Sticky === 1 ? "active" : ""}`} onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth" }) }}>
            <i className="fa-solid fa-caret-up text-white"></i>
        </div>
    )
}


export default function Module({ module, props, children }) {
    switch (module) {
        case "time": return <Timer props={props} />
        case "date": return <Dater props={props} />
        case "header": return <Header props={props} />
        case "footer": return <Footer props={props} />
        case "upload": return <Upload props={props} />
        case "section": return <Section props={props} children={children} />
        case "clicktop": return <Clicktop />

    }
}