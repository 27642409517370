import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Element from "../library/element";
import Module from "../library/module";
import GlobalBackground from "../assets/img/global-background.webp"
import { Helmet } from "react-helmet";


function Menu() {

    const { name } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [Data, setData] = useState({ title: "", data: <></> })
    const [Global, setGlobal] = useState([])
    const [Lang, setLang] = useState("DEUTSCH")
    const [distanceFromBottom, setDistanceFromBottom] = useState(0);
    const [Seo, setSeo] = useState({ title: "", description: "", keywords: "" })
    const Load = async (name) => {
        const seoresponse = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_page_seo&conditions=company_id='20242119' AND page_id='_page_menu'`)
        if (seoresponse.data.length > 0) {
            var r = "";
            if (seoresponse["data"]["keyword"] !== "") {
                var j = JSON.parse(seoresponse["data"][0]["keyword"]);
                for (let i = 0; i < j.length; i++) {
                    r += j[i]["keyword"] + ", ";
                }
            }
            setSeo({
                title: seoresponse["data"][0]["title"],
                description: seoresponse["data"][0]["description"],
                keywords: r
            })
        }
        const res = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_menu&conditions=company_id='20242119'`)
        setGlobal(res.data)
        setTimeout(() => {
            const response = res.data.filter((d) => { return (Element({ element: "route", props: { url: d["title"] } }).includes(name) && JSON.parse(d["lang"])["language"] === Lang) })
            if (response.length > 0) {
                const title = response[0]["title"];
                const file = JSON.parse(response[0]["file"]);
                var result = [];
                for (let i = 0; i < file.length; i++) {
                    result[i] =
                        <div className="col-lg-6">
                            <img src={`${Element({ element: "root", props: "file" })}/${file[i]["blob"]}`} alt={file[i]["blob"]} className="border menu-detail-image shadow-sm" />
                        </div>;
                }
                setData({
                    title: title,
                    data:
                        <div className="row mt-2 mb-2 d-flex justify-content-center">
                            {result}
                        </div>
                })
            }
        }, 1000);

    }
    const Select = async (type) => {
        if (name === "tagesmenu") {
            axios.post(`https://capri-adliswil.ch/capri/class.pdf.php`, {}, { responseType: 'arraybuffer' }).then((data) => {
                const blob = new Blob([data.data], { type: 'image/png' });
                const url = URL.createObjectURL(blob);
                setData({
                    title: "TAGESMENÜ",
                    data:
                        <div className="row mt-2 mb-2 d-flex justify-content-center">
                            <div className="col-lg-6">
                                <img src={url} alt={url} className="border menu-detail-image shadow-sm bg-white" />
                            </div>
                        </div>
                })
            })
        }
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = document.documentElement.scrollHeight;
            const viewportHeight = window.innerHeight;
            const newDistanceFromBottom = scrollHeight - (window.scrollY + viewportHeight);
            if (newDistanceFromBottom >= 500) {
                setDistanceFromBottom(1);
            }
            else {
                setDistanceFromBottom(0);
            }

        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        setData({
            title: "",
            data:
                <div className="row mt-2 mb-2 d-flex justify-content-center">
                    <div className="col-lg-6">
                        <div className="loader-container border">
                            <div className="loader"></div>
                        </div>
                    </div>
                </div>
        })
        window.scroll({
            top: 150,
            left: 0,
        });
        Select(name)
        Load(name)
    }, [name])
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pizzeria Capri | {Seo.title} - {Data["title"]}</title>
                <link rel="canonical" href="https://www.thawolo.ch/" />
                <meta name="description" content={Seo.description} />
                <meta name="keywords" content={Seo.keywords} />
                <meta property="og:title" name="description" content={Seo.title} />
                <meta property="og:description" name="description" content={Seo.description} />
            </Helmet>
            <Module
                module="section"
                props={{
                    classname: { container: "", inner: "container  lg-pt-c20 lg-pb-c1 md-pt-c10 md-pb-c1 ", body: "", },
                    title: <div className="lg-pt-c5 lg-pb-c3 md-pt-c3 md-pb-c1 section-title">{Data["title"]}</div>,
                    text: "",
                    url: GlobalBackground
                }}>
                {Data["data"]}
                <div className="sticky-footer-container">
                    <div className={`d-lg-flex ${distanceFromBottom === 1 ? "sticky-footer md-mt-5 md-mb-5 shadow-sm" : "mt-5 mb-5"}`}>
                        <button className={`btn btn-capri w-100 me-2 mb-2 ${name === "tagesmenu" ? "active" : ""}`} onClick={() => { navigate(`/menu/tagesmenu`) }}>
                            TAGESMENÜ
                        </button>
                        {
                            (Global || []).filter((d) => { return (JSON.parse(d["lang"])["language"] === Lang) }).map((d, x) => {
                                return (
                                    <button className={`btn btn-capri w-100 me-2 mb-2 ${name === Element({ element: "route", props: { url: d["title"] } }) ? "active" : ""}`} onClick={() => { navigate(`/menu/${Element({ element: "route", props: { url: d["title"] } })}`) }}>
                                        {d["title"]}
                                    </button>
                                )
                            })
                        }
                    </div>
                    {distanceFromBottom === 1 ? <div className="mt-5 mb-5 md-d-none">
                        <button className={`btn btn-capri w-100 me-2 mb-2`} style={{ opacity: 0 }}>
                            TAGESMENÜ
                        </button>

                    </div> : <></>}
                </div>
            </Module>
        </>
    )
}
export default Menu;