import { useEffect, useState } from "react";
import Element from "../library/element";
import Module from "../library/module";
import axios from "axios";
import GlobalBackground from "../assets/img/global-background.webp"
import { Helmet } from "react-helmet";

function Contact({ props }) {

    const [Gender, setGender] = useState("")
    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Comment, setComment] = useState("")
    const [Error, setError] = useState({ status: true, target: 0, type: "", text: "" })
    const [Seo, setSeo] = useState({
        title: "",
        description: "",
        keywords: ""
    })
    const Load = async () => {
        const seoresponse = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_page_seo&conditions=company_id='20242119' AND page_id='_page_kontakt'`)
        if (seoresponse.data.length > 0) {
            var r = "";
            if (seoresponse["data"]["keyword"] !== "") {
                var j = JSON.parse(seoresponse["data"][0]["keyword"]);
                for (let i = 0; i < j.length; i++) {
                    r += j[i]["keyword"] + ", ";
                }
            }
            setSeo({
                title: seoresponse["data"][0]["title"],
                description: seoresponse["data"][0]["description"],
                keywords: r
            })
        }
    }
    const Control = () => {
        if (Gender === "") { setError({ status: false, target: 1, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (User === "") { setError({ status: false, target: 2, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Email === "") { setError({ status: false, target: 3, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Phone === "") { setError({ status: false, target: 4, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Comment === "") { setError({ status: false, target: 5, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        return true;
    }
    const Send = () => {
        if (Control()) {
            const root = Element({ element: "root", props: "default" });
            //FIRMA
            const companyBody = `
                Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf.
                <br/><br/>
                Anrede: ${Gender}
                <br/>
                Name und Vorname: ${User}
                <br/>
                Telefon: ${Phone}
                <br/>
                E-Mail: ${Email}
                <br/>
                Bemerkungen: ${Comment}
                <br/>
            `
            const companyData = {
                id: "20242119",
                email: ["info@capri-adliswil.ch", "n.n.akcakaya@gmail.com"],
                title: "Neue Nachricht",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })


            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${User}` : `Sehr geehrter ${Gender} ${User}`}
                <br/><br/>
                Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.
                <br/><br/>
                Freundlichen Grüsse
                <br/><br/>
                Capri Team
                <br/><br/>
                Pizzeria Capri
                <br/>
                Kilchbergstrasse 4
                <br/>
                8134 Adliswil
                <br/><br/>
                info@capri-adliswil.ch
                <br/>
                044 710 44 44
                <br/>
            `;
            const customerData = {
                id: "20242119",
                email: ["" + Email + ""],
                title: "Ihre Nachricht ist bei uns angekommen",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank!" });
            setGender("")
            setUser("")
            setEmail("")
            setPhone("")
            setComment("")
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pizzeria Capri | {Seo.title}</title>
                <link rel="canonical" href="https://www.thawolo.ch/" />
                <meta name="description" content={Seo.description} />
                <meta name="keywords" content={Seo.keywords} />
                <meta property="og:title" name="description" content={Seo.title} />
                <meta property="og:description" name="description" content={Seo.description} />
            </Helmet>
            <div style={{ height: "80px" }}></div>
            <Module module="section" props={{ classname: { container: "lg-pb-c5 md-pb-c5", inner: "container", body: "", }, title: <div className="lg-pt-c20 lg-pb-c5 md-pt-c7 md-pb-c2 section-title">KONTAKT</div>, text: "", url: GlobalBackground }}>
                <div className="row d-flex justify-content-center ">
                    <div className="col-lg-4 col-12 lg-pb-c2 md-pb-c2">
                        <div className="card lg-mt-5">
                            <div className="card-body text-center">
                                <i className="fa-solid fa-square-phone-flip fs-3 mb-3 color-capri"></i>
                                <div className="contact-title">Telefon</div>
                                <div className="contact-link cursor-pointer lg-pt-c1" onClick={() => { window.open(props?.phone) }}>
                                    {props?.phone}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 lg-pb-c2 md-pb-c2">
                        <div className="card lg-mt-5">
                            <div className="card-body text-center ">
                                <i className="fa-solid fa-envelope fs-3 mb-3 color-capri"></i>
                                <div className="contact-title">E-Mail</div>
                                <div className="contact-link cursor-pointer lg-pt-c1" onClick={() => { window.open(props?.email) }}>
                                    {props?.email}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 lg-pb-c2 md-pb-c2">
                        <div className="card lg-mt-5">
                            <div className="card-body text-center">
                                <i className="fa-solid fa-location-dot fs-3 mb-3 color-capri"></i>
                                <div className="contact-title">Routenplaner</div>
                                <div className="contact-link cursor-pointer lg-pt-c1" onClick={() => { window.open(props?.maps) }}>
                                    Unser Standort
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                            {Error.text}
                        </div>
                    </div>
                    <div className="col-lg-8 md-pb-c2">
                        <div className="card calc-height">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Andrede *</label>
                                            <select className={`form-control h-50px shadow-sm cursor-pointer ${Error.target === 1 ? "border-red" : ""}`} onChange={(e) => { setGender(e.target.value); setError({ status: true, type: "", text: "" }) }}>
                                                <option value={0}>Andrede</option>
                                                <option value={"Frau"}>Frau</option>
                                                <option value={"Herr"}>Herr</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Name und Vorname *</label>
                                            <input className={`form-control h-50px shadow-sm ${Error.target === 2 ? "border-red" : ""}`} placeholder="Name und Vorname" value={User} onChange={(e) => { setUser(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">E-Mail *</label>
                                            <input className={`form-control h-50px shadow-sm ${Error.target === 3 ? "border-red" : ""}`} placeholder="E-Mail" value={Email} onChange={(e) => { setEmail(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Telefon *</label>
                                            <input className={`form-control h-50px shadow-sm ${Error.target === 4 ? "border-red" : ""}`} placeholder="Telefon" value={Phone} onChange={(e) => { setPhone(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-4 lg-pb-2">
                                        <div className="mb-2">
                                            <label className="mb-1 label-text">Nachricht *</label>
                                            <textarea className={`form-control shadow-sm ${Error.target === 5 ? "border-red" : ""}`} placeholder="Nachricht..." rows={5} value={Comment} onChange={(e) => { setComment(e.target.value); setError({ status: true, type: "", text: "" }) }}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="mb-2">
                                            <button className="btn btn-capri w-100" onClick={() => { Send() }}>SENDEN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 md-pb-c2">
                        <div className="card calc-height">
                            <div className="card-body ">
                                <div className="row ">
                                    <div className="col-lg-12 lg-pb-10 md-pb-15">
                                        <div className="d-flex">
                                            <div className="w-40px me-1">
                                                <i className="fa-solid fa-location-dot mb-3 mt-1 fs-3" style={{ color: "#a48f7a" }}></i>
                                            </div>
                                            <div className="w-100">
                                                <div className="contact-address mb-2 mt-1">Adress</div>
                                                <div className="contact-text mb-1 mt-2">
                                                    {props?.name}<br />
                                                    {props?.district} <br />
                                                    {props?.address}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 lg-pb-10 md-pb-15">
                                        <div className="d-flex">
                                            <div className="w-40px me-1">
                                                <i className="fa-solid fa-square-phone-flip fs-3 mb-3 mt-1" style={{ color: "#a48f7a" }}></i>
                                            </div>
                                            <div className="w-100">
                                                <div className="contact-address mb-1 mt-1">Telefon</div>
                                                <div className="contact-text cursor-pointer mt-2" onClick={() => { window.open(`tel:${props?.phone}`) }}>
                                                    {props?.phone}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 lg-pb-10 md-pb-15">
                                        <div className="d-flex">
                                            <div className="w-40px me-1">
                                                <i className="fa-solid fa-envelope fs-3 mb-3 mt-1" style={{ color: "#a48f7a" }}></i>
                                            </div>
                                            <div className="w-100">
                                                <div className="contact-address mb-1 mt-1">E-mail</div>
                                                <div className="contact-text cursor-pointer mt-2" onClick={() => { window.open(`mailto:${props?.email}`) }}>
                                                    {props?.email}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <iframe className="contact-map " src={props?.maps}></iframe>
        </>
    )
}
export default Contact;