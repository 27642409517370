import axios from "axios";
import { useEffect, useState } from "react";
import Module from "../library/module";
import Element from "../library/element";
import GlobalBackground from "../assets/img/global-background.webp"
import { Helmet } from "react-helmet";

function Feiern() {

    const [Dates, setDates] = useState("")
    const [Gender, setGender] = useState(0)
    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Comment, setComment] = useState("")
    const [Error, setError] = useState({ status: true, target: 0, type: "", text: "" })

    const [Data, setData] = useState({
        image: "",
        slogan: "",
        title: "",
        text: "",
        email: "",
        phone: ""
    })
    const [Seo, setSeo] = useState({
        title: "",
        description: "",
        keywords: ""
    })
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_page&conditions=company_id='20242119' AND page_id='_page_feiern'`)
        const seoresponse = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_page_seo&conditions=company_id='20242119' AND page_id='_page_galerie'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);
            setData({
                image: r["image"]["blob"],
                slogan: r["slogan"],
                title: r["title"],
                text: r["text"],
                email: r["email"],
                phone: r["phone"],
            })
        }

        if (seoresponse.data.length > 0) {
            var r = "";
            if (seoresponse["data"]["keyword"] !== "") {
                var j = JSON.parse(seoresponse["data"][0]["keyword"]);
                for (let i = 0; i < j.length; i++) {
                    r += j[i]["keyword"] + ", ";
                }
            }
            setSeo({
                title: seoresponse["data"][0]["title"],
                description: seoresponse["data"][0]["description"],
                keywords: r
            })
        }
    }
    const Control = () => {

        if (Dates === "") { setError({ status: false, target: 1, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Gender === 0) { setError({ status: false, target: 2, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (User === "") { setError({ status: false, target: 3, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Email === "") { setError({ status: false, target: 4, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Phone === "") { setError({ status: false, target: 5, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        return true;
    }
    const Send = () => {
        if (Control()) {
            const root = Element({ element: "root", props: "default" });
            //FIRMA
            const companyBody = `
                Sie haben eine neue Veranstaltungsanfrage erhalten. Die folgenden Informationen gehören dem Antragsteller. Bitte setzen Sie sich mit dem Bewerber in Verbindung.
                <br/><br/>
                gewünschtes Datum:: ${Dates}
                <br/>
                Anrede: ${Gender}
                <br/>
                Name und Vorname: ${User}
                <br/>
                E-Mail: ${Email}
                <br/>
                Telefon: ${Phone}
                <br/>
                Bemerkungen: ${Comment}
                <br/>
            `
            const companyData = {
                id: "20242119",
                email: ["info@capri-adliswil.ch", "n.n.akcakaya@gmail.com"],
                title: "NEUE TISCH RESERVATION",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })
            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${User}` : `Sehr geehrter ${Gender} ${User}`}
                <br/><br/>
                Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.
                <br/><br/>
                Freundlichen Grüsse
                <br/><br/>
                Capri Team
                <br/><br/>
                Pizzeria Capri
                <br/>
                Kilchbergstrasse 4
                <br/>
                8134 Adliswil
                <br/><br/>
                info@capri-adliswil.ch
                <br/>
                044 710 44 44
                <br/>
            `;
            const customerData = {
                id: "20242119",
                email: ["" + Email + ""],
                title: "Ihre Nachricht ist bei uns angekommen",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Diese Felder müssen ausgefüllt werden." });
            setDates("")
            setGender("")
            setUser("")
            setEmail("")
            setPhone("")
            setComment("")
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pizzeria Capri | {Seo.title}</title>
                <link rel="canonical" href="https://www.thawolo.ch/" />
                <meta name="description" content={Seo.description} />
                <meta name="keywords" content={Seo.keywords} />
                <meta property="og:title" name="description" content={Seo.title} />
                <meta property="og:description" name="description" content={Seo.description} />
            </Helmet>
            <Module module="section" props={{ classname: { container: "", inner: "md-pt-c8 md-pb-c8", body: "", }, title: "", text: "", url: GlobalBackground }}>
                <div className="row m-0">
                    <div className="row m-0">
                        <div className="col-lg-6 p-0">
                            <div style={{ backgroundImage: `url(${Data["image"]})` }} className="feiern-image"></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row d-flex justify-content-center  lg-pt-c20 lg-pb-c10">
                                <div className="col-lg-10">
                                    <div className="feiern-title lg-pb-5 md-pt-c3 md-pt-c0 md-pb-c2">{Data["title"]}</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="feiern-text lg-pt-c0 lg-pb-c2" dangerouslySetInnerHTML={{ __html: Data["text"] }}></div>
                                </div>
                                <div className="col-lg-6 col-12 lg-pt-c0 lg-pb-c2">
                                    <div className="feiern-phone cursor-pointer" onClick={() => { window.open(`tel:${Data["phone"]}`) }}>{Data["phone"]}</div>
                                </div>
                                <div className="col-lg-6 col-12 lg-pt-c0 lg-pb-c2 md-pt-c0 md-pb-c2">
                                    <div className="feiern-email cursor-pointer" onClick={() => { window.open(`mailto:${Data["email"]}`) }}>{Data["email"]}</div>
                                </div>
                                <div className="col-lg-12">
                                    <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                                        {Error.text}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card w-100">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Datum auswählen  *</label>
                                                        <Module module="date" props={{ response: (e) => { setDates(`${e["day"]}.${e["month"]}.${e["year"]}`);  setError({ status: true, type: "", text: "" }) }, classname: `${parseInt(Error.target) === 1 ? "border-red" : ""}` }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Andrede  *</label>
                                                        <select className={`form-control h-50px shadow-sm cursor-pointer ${parseInt(Error.target) === 2 ? "border-red" : ""}`} onChange={(e) => { setGender(e.target.value); setError({ status: true, type: "", text: "" }) }}>
                                                            <option value={0}>Andrede</option>
                                                            <option value={"Frau"}>Frau</option>
                                                            <option value={"Herr"}>Herr</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Name und Vorname  *</label>
                                                        <input className={`form-control h-50px shadow-sm ${parseInt(Error.target) === 3 ? "border-red" : ""}`} placeholder="Name und Vorname *" value={User} onChange={(e) => { setUser(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">E-Mail Adresse *</label>
                                                        <input className={`form-control h-50px shadow-sm form-control h-50px shadow-sm ${parseInt(Error.target) === 4 ? "border-red" : ""}`} placeholder="E-Mail Adresse *" value={Email} onChange={(e) => { setEmail(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Telefon *</label>
                                                        <input className={`form-control h-50px shadow-sm form-control h-50px shadow-sm form-control h-50px shadow-sm ${parseInt(Error.target) === 5 ? "border-red" : ""}`} placeholder="Telefon *" value={Phone} onChange={(e) => { setPhone(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Nachricht  *</label>
                                                        <textarea className={`form-control shadow-sm ${parseInt(Error.target) === 6 ? "border-red" : ""}`} placeholder="Nachricht... *" rows={5} value={Comment} onChange={(e) => { setComment(e.target.value); setError({ status: true, type: "", text: "" }) }}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 md-p-0 mt-2">
                                                    <div className="mb-2">
                                                        <button className="btn btn-capri w-100" onClick={() => { Send() }}>SENDEN</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Feiern;