
const Root = ({ props }) => {
    switch (props) {
        case "default": return "https://ristorante-olivo.ch/api/api.php"
        case "upload": return "https://ristorante-olivo.ch/api/class.upload.php"
        case "file": return "https://ristorante-olivo.ch/api/upload"
    }
}

const Route = ({ props }) => {
    return props.url.toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/&/g, '-and-')
    .replace(/[^a-z0-9\-]/g, '')
    .replace(/-+/g, '-')
    .replace(/^-*/, '')
    .replace(/-*$/, '');
}



export default function Element({ element, props }) {
    switch (element) {
        case "root": return Root({ props: props })
        case "route": return Route({ props: props })
    }
}