import Module from "../library/module";
import GlobalBackground from "../assets/img/global-background.webp"
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Element from "../library/element";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Home() {
    const videoref = useRef(null)
    const navigation = useNavigate()
    const [isPlaying, setIsPlaying] = useState(false);
    const Play = (e) => {
        if (e.type) {
            setIsPlaying(true)
        }
    }
    const Pause = (e) => {
        if (e.type) {
            setIsPlaying(false)
        }
    }
    const [Content, setContent] = useState({
        banner: {
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" }
        },
        aboutus: {
            slogan: "",
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" },
            signatur: ""
        },
        video: {
            blob: { id: 0, blob: "", file: "" },
            video: { id: 0, blob: "", file: "" },
            title: "",
            text: ""
        },
        welcome: {
            slogan: "",
            title: "",
            text: "",
            signatur: "",
            blob: [
                {
                    id: 1,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 2,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 3,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 4,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                }
            ]
        },
        parallax: {
            title: "",
            prallax: { id: 0, blob: "", file: "" },
            blob: { id: 0, blob: "", file: "" },
        },
        menu: {
            blob: [
                {
                    id: 1,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 2,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 3,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                }
            ]
        },
        feiern: {
            slogan: "",
            title: "",
            text: "",
            phone: "",
            email: "",
            blob: { id: 0, blob: "", file: "" },
        }
    })
    const [Seo, setSeo] = useState({
        title: "",
        description: "",
        keywords: ""
    })
    const Load = async () => {
        const pageresponse = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_page&conditions=company_id='20242119' AND page_id='_page_home'`)
        const seoresponse = await axios.get(`${Element({ element: "root", props: "default" })}?params=select&table=kp_page_seo&conditions=company_id='20242119' AND page_id='_page_home'`)
        if (pageresponse.data.length > 0) {
            setContent(JSON.parse(pageresponse.data[0]["content"]))
            if (videoref.current) {
                videoref.current.src = JSON.parse(pageresponse.data[0]["content"])["video"]["video"]["blob"]
            }
        }
        if (seoresponse.data.length > 0) {
            var r = "";
            if (seoresponse["data"]["keyword"] !== "") {
                var j = JSON.parse(seoresponse["data"][0]["keyword"]);
                for (let i = 0; i < j.length; i++) {
                    r += j[i]["keyword"] + ", ";
                }
            }
            setSeo({
                title: seoresponse["data"][0]["title"],
                description: seoresponse["data"][0]["description"],
                keywords: r
            })
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pizzeria Capri | {Seo.title}</title>
                <link rel="canonical" href="https://www.thawolo.ch/" />
                <meta name="description" content={Seo.description} />
                <meta name="keywords" content={Seo.keywords} />
                <meta property="og:title" name="description" content={Seo.title} />
                <meta property="og:description" name="description" content={Seo.description} />
            </Helmet>
            <Module module="section" props={{ classname: { container: "banner", inner: "container", body: "", }, title: "", text: "", url: Content.banner.blob.blob }}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="banner-inner">
                            <div className="banner-info">
                                <div>
                                    <div className="banner-title">
                                        {Content.banner.title}
                                    </div>
                                    <div className="banner-text">
                                        {Content.banner.text}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6"></div>
                </div>
            </Module>
            <Module module="section" props={{ classname: { container: "", inner: "container", body: "lg-pt-c2 lg-pb-c2 md-pt-c3 md-pb-c3", }, title: "", text: "", url: GlobalBackground }}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="inner">
                            <div>
                                <img src={Content.aboutus.blob.blob} className="image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <div>
                                <div className="slogan">{Content.aboutus.slogan}</div>
                                <div className="title md-pt-c1">{Content.aboutus.title}</div>
                                <div className="text lg-pt-c1 md-pt-c2" dangerouslySetInnerHTML={{ __html: Content.aboutus.text }}></div>
                                <div className="singnatur lg-pt-c1 md-pt-c1">{Content.aboutus.signatur}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" props={{ classname: { container: "", inner: "video-container", body: "lg-pt-c2 lg-pb-c2 md-pt-c3 md-pb-c3", }, title: "", text: "", url: GlobalBackground }}>
                <video ref={videoref} controls onPlay={Play} onPause={Pause} poster={Content.video.blob.blob}></video>
                <div className="row m-0 d-flex justify-content-center video-inner md-d-none lg-d-flex">
                    <div className="col-lg-6 col-10 m-0 p-0">
                        <div className="video-info shadow-sm" hidden={isPlaying}>
                            <div>
                                <div className="video-title">{Content.video.title}</div>
                                <div className="video-text">{Content.video.text}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </Module>
            <Module module="section" props={{ classname: { container: "", inner: "container", body: "lg-pt-c2 lg-pb-c2", }, title: "", text: "", url: GlobalBackground }}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="inner lg-pt-c5 lg-pb-c5 md-pt-c2 md-pb-c2">
                            <div className="row">
                                {
                                    Content.welcome.blob.map((d, x) => {
                                        return (
                                            <div className={`col-lg-6 col-6 ${x % 2 === 0 ? "lg-pt-c7" : "md-pb-c2"}`}>
                                                <div className="card rounded-2 shadow-sm" onClick={() => { navigation(d["link"]) }}>
                                                    <img src={d["blob"]["blob"]} className="card-image rounded-2" />
                                                    <div className="card-title">
                                                        {d["title"]}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <div>
                                <div className="slogan">{Content.welcome.slogan}</div>
                                <div className="title lg-pt-c1 md-pt-c1">{Content.welcome.title}</div>
                                <div className="text lg-pt-c2 md-pt-c1" dangerouslySetInnerHTML={{ __html: Content.welcome.text }}></div>
                                <div className="singnatur lg-pt-c1 md-pt-c1 md-pb-c3">{Content.welcome.signatur}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" props={{ classname: { container: "parallax", inner: "container", body: "lg-pt-c2 lg-pb-c2 md-pt-c2 md-pb-c2", }, title: "", text: "", url: Content.parallax.prallax.blob }}>
                <div className="row lg-pt-c10 lg-pb-c10">
                    <div className="col-lg-12">
                        <div className="inner justify-content-center">
                            <div>
                                <div className="parallax-title lg-pb-c4 md-pb-c4 md-pt-c4">{Content.parallax.title}</div>
                                <img src={Content.parallax.blob.blob} className="parallax-image md-pb-c4" />
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" props={{ classname: { container: "", inner: "container", body: "lg-pt-c6 lg-pb-c3 md-pt-c6 md-pb-c0", }, title: "", text: "", url: GlobalBackground }}>
                <div className="row">
                    {
                        Content.menu.blob.map((d, x) => {
                            return (
                                <div className="col-lg-4 md-pb-c3">
                                    <div className="card rounded-2 shadow-sm cursor-pointer" onClick={() => { d["title"] === "HAUSLIEFERUNG" ? window.open(d["link"]) : navigation(d["link"]) }}>
                                        <div className=" bg-image" style={{ backgroundImage: `url(${d["blob"]["blob"]})` }}></div>
                                        <div className="card-title">{d["title"]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Module>
            <Module module="section" props={{ classname: { container: "", inner: "container", body: "lg-pt-c4 lg-pb-c8 md-pt-c4 md-pb-c8", }, title: "", text: "", url: GlobalBackground }}>
                <div className="row">
                    <div className="col-lg-6 lg-d-none">
                        <div className="inner">
                            <div className="feiern-image rounded-2 shadow-sm" style={{ backgroundImage: `url(${Content.feiern.blob.blob})` }}>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <div>
                                <div className="slogan md-pb-c0 md-pt-c3">{Content.feiern.slogan}</div>
                                <div className="title md-pb-c1">{Content.feiern.title}</div>
                                <div className="text lg-pt-c1 lg-pb-c1" dangerouslySetInnerHTML={{ __html: Content.feiern.text }}></div>
                                <div className="lg-d-flex md-d-block lg-pt-c1 md-pt-c2 md-pb-c2">
                                    <div className="phone me-4 d-flex align-items-center" onClick={() => { window.open(`tel:${Content.feiern.phone}`) }}>
                                        <i class="fa-solid fa-square-phone me-1 md-d-none lg-d-block"></i>
                                        {Content.feiern.phone}
                                    </div>
                                    <div className="email d-flex align-items-center" onClick={() => { window.open(`mailto:${Content.feiern.email}`) }}>
                                        <i class="fa-solid fa-envelope me-1 md-d-none lg-d-block"></i>
                                        {Content.feiern.email}
                                    </div>
                                </div>
                                <div className="d-flex lg-pt-c2 lg-pb-c2 md-pt-c2 md-pb-c2">
                                    <div className="btn btn-capri w-100" onClick={() => { navigation("/feiern") }}>DETAILS</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 md-d-none lg-d-block">
                        <div className="inner">
                            <div className="feiern-image rounded-2 shadow-sm" style={{ backgroundImage: `url(${Content.feiern.blob.blob})` }}>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Home;